.todo-list {
  margin-top: 20px;
}

.todo-list h2 {
  margin-bottom: 10px;
}

.todo-list form {
  display: flex;
  margin-bottom: 20px;
}

.todo-list input[type="text"] {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

.todo-list input[type="number"] {
  width: 60px;
  padding: 5px;
  margin-right: 10px;
}

.todo-list button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.todo-list ul {
  list-style-type: none;
  padding: 0;
}

.todo-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.todo-list li button {
  background-color: #FF6B6B;
}