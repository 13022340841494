:root {
  --red: #FF6B6B;
  --white: #FFFFFF;
  --yellow: #FFD93D;
  --background: #F4F4F4;
  --gray: #CCCCCC;
  --dark-gray: #333333;
  --green: #4CAF50;
  --light-green: #8BC34A;

  /* Light mode variables */
  --text-color: var(--dark-gray);
  --bg-color: var(--background);
  --container-bg: var(--white);
  --container-shadow: rgba(0, 0, 0, 0.1);
  --input-border: var(--gray);
  --todo-item-bg: var(--background);
  --timer-text-color: var(--dark-gray);
}

.dark-mode {
  --text-color: var(--white);
  --bg-color: #1a1a1a;
  --container-bg: #2a2a2a;
  --container-shadow: rgba(255, 255, 255, 0.1);
  --input-border: #444;
  --todo-item-bg: #333;
  --timer-text-color: var(--white);
}

body {
  font-family: 'Space Grotesk', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1, h2 {
  color: var(--red);
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

button, input[type="submit"] {
  font-family: 'Space Grotesk', sans-serif;
  background-color: var(--red);
  color: var(--white);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  margin: 0.25rem;
  font-weight: bold;
}

button:hover, input[type="submit"]:hover {
  background-color: var(--light-green);
  color: var(--dark-gray);
  transform: translateY(-2px);
  box-shadow: 0 2px 4px var(--container-shadow);
}

input, select {
  font-family: 'Space Grotesk', sans-serif;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  margin: 0.25rem;
  transition: all 0.3s ease;
  background-color: var(--bg-color);
  color: var(--text-color);
}

input:focus, select:focus {
  outline: none;
  border-color: var(--red);
  box-shadow: 0 0 0 2px rgba(255, 107, 107, 0.2);
}

select {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FF6B6B%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  padding-right: 2rem;
}

.container {
  background-color: var(--container-bg);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px var(--container-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.todo-list ul {
  list-style-type: none;
  padding: 0;
}

.todo-list li {
  background-color: var(--todo-item-bg);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.todo-list li span {
  cursor: pointer;
  flex-grow: 1;
  text-align: left;
}

.todo-list li button {
  margin-left: 0.5rem;
}

.error-message {
  color: var(--red);
  margin-bottom: 1rem;
}

.loading-indicator {
  color: var(--white);
  margin-top: 1rem;
}

/* Dark mode toggle button styles */
.dark-mode-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Timer styles */
.timer-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.timer-tabs button {
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--red);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.timer-tabs button.active {
  background-color: var(--yellow);
  color: var(--red);
  border-color: var(--yellow);
}

.timer-tabs button:hover {
  background-color: var(--yellow);
  color: var(--red);
  border-color: var(--yellow);
}

.time-display {
  font-size: 3rem;
  font-weight: bold;
  margin: 1rem 0;
  color: var(--timer-text-color);
}

.timer button {
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
  margin: 0.5rem;
  background-color: var(--red);
  color: var(--white);
}

.timer button:hover {
  background-color: var(--yellow);
  color: var(--red);
}

.selected-task {
  margin-bottom: 1rem;
  font-style: italic;
  color: var(--timer-text-color);
}

/* User info and logout styles */
.user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--container-bg);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--container-shadow);
}

.user-email {
  font-size: 1rem;
  color: var(--text-color);
  margin-right: 1rem;
  font-weight: bold;
}

.logout-button {
  background-color: var(--red);
  color: var(--white);
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: var(--yellow);
  color: var(--red);
}

/* Strikethrough style for completed tasks */
.completed-task {
  text-decoration: line-through;
  color: #888;
}